html {
  scroll-behavior: smooth;
 }

a {
  text-decoration: none;
  color: black;
}

a[href='http://www.onlinewebfonts.com'] {
    display: none;
}

#down {
  margin-top: 100%;
  padding-bottom: 25%;
}

html,body{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

.logo {
    max-width: 165px;
    height: auto;
}

.MuiTypography-h1.coming-soon-text {
    color: transparent;
    padding-bottom: 50px;
}

.makeStyles-root-15, .jss15 {
    margin-bottom: 13rem;
}

.makeStyles-leftContent-13, .jss13 {
    margin-top: 15rem;
}

.coming-soon-text {
    font-size: 3em;
    margin: 0 auto;
    display: inline;
    white-space: nowrap;
    background: -webkit-radial-gradient(circle, #fff, #D8BF95, #D8BF95);
    background: -moz-radial-gradient(circle, #fff, #D8BF95, #D8BF95);
    background: radial-gradient(circle, #fff, #D8BF95, #D8BF95);
    -webkit-background-size: 125px 100%;
    -moz-background-size: 125px 100%;
    background-size: 125px 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-animation: shimmer 2.5s ease-out 1s, spacing 1.5s ease-in;
    -moz-animation: shimmer 2.5s ease-out 1s, spacing 1.5s ease-in;
    animation: shimmer 2.5s ease-out 1s, spacing 1.5s ease-in;
    background-position-x: -110%;
    background-repeat: no-repeat;
    background-color: #D8BF95;
}

@media only screen and (min-height: 700px) {
    .makeStyles-root-15, .jss15 {
        margin-bottom: 18rem;
    }
}

@media only screen and (min-width: 600px) {
    .makeStyles-root-15, .jss15 {
        margin-bottom: 3rem;
    }

    .makeStyles-leftContent-13, .jss13 {
        margin-top: -6rem;
    }
}

@media only screen and (max-width: 1024px) {
    .logo {
        max-width: 240px;
    }
}

@media only screen and (min-width: 1024px) {

    .makeStyles-root-15, .jss15 {
        margin-bottom: 0;
    }

    .makeStyles-leftContent-13, .jss13 {
        margin-top: 0;
    }
}

@-moz-keyframes shimmer {
    0% {
        background-position-x: -110%;
    }
    100% {
        background-position-x: 110%;
    }
}
@-webkit-keyframes shimmer {
    0% {
        background-position-x: -110%;
    }
    100% {
        background-position-x: 110%;
    }
}
@-o-keyframes shimmer {
    0% {
        background-position-x: -110%;
    }
    100% {
        background-position-x: 110%;
    }
}
@keyframes shimmer {
    0% {
        background-position-x: -110%;
    }
    100% {
        background-position-x: 110%;
    }
}

@keyframes spacing {
    0% {
        opacity: 0;
        letter-spacing: 20px;
    }
    100% {
        opacity: 1;
        letter-spacing: inherit;
    }
}

@-moz-keyframes spacing {
    0% {
        opacity: 0;
        letter-spacing: 20px;
    }
    100% {
        opacity: 1;
        letter-spacing: inherit;
    }
}

@-webkit-keyframes spacing {
    0% {
        opacity: 0;
        letter-spacing: 20px;
    }
    100% {
        opacity: 1;
        letter-spacing: inherit;
    }
}
@-o-keyframes spacing {
    0% {
        opacity: 0;
        letter-spacing: 20px;
    }
    100% {
        opacity: 1;
        letter-spacing: inherit;
    }
}

